import './App.css';
import About from './shared/about';
import Header from './shared/header';
import Slider from './shared/slider';


function App() {
  return (
    <>
      <Header />
      <Slider />
      <About />
    </>
  );
}

export default App;
