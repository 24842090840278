import { useLayoutEffect } from "react";

function Header() {
    useLayoutEffect(() => {
        // @ts-ignore
        /*---------------------
         TOP Menu Stick
        --------------------- */
        var s = $("#sticker");
        var pos = s.position();
        $(window).on('scroll', function () {
            // @ts-ignore
            var windowpos = $(window).scrollTop() > 300;
            // @ts-ignore
            if (windowpos > pos.top) {
                s.addClass("stick");
            } else {
                s.removeClass("stick");
            }
        });

    }, [])


    return (
        <header>

            <div id="sticker" className="header-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">


                            <nav className="navbar navbar-default">

                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target=".bs-example-navbar-collapse-1" aria-expanded="false">
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>

                                    <a className="navbar-brand page-scroll sticky-logo" href="index.html">
                                        <h1><span>T</span>oraki</h1>


                                    </a>
                                </div>

                                <div className="collapse navbar-collapse main-menu bs-example-navbar-collapse-1" id="navbar-example">
                                    <ul className="nav navbar-nav navbar-right">
                                        <li className="active">
                                            <a className="page-scroll" href="/">Home</a>
                                        </li>
                                        <li>
                                            <a className="page-scroll" href="#about">Contact</a>
                                        </li>
                                    </ul>
                                </div>

                            </nav>

                        </div>
                    </div>
                </div>
            </div>

        </header>
    );
}

export default Header;