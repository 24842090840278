import { useLayoutEffect } from "react";

function About() {

    useLayoutEffect(() => {
        // @ts-ignore

    }, [])
    
    return (
        <>
            <div id="about" className="about-area area-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="section-headline text-center">
                                <h2>About Toraki</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="well-left">
                                <div className="single-well">
                                        <img src="/assets/img/about/About_1.jpg" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="well-middle">
                                <div className="single-well"  >
                                    <h4 className="sec-head">Toraki makes serious products.</h4>
                                    <p >
                                    Thank you for continuing to select Toraki products. 
                                    For those of you that have not tried our products, I invite you to experience what your fellow martial artists have known for years.
                                    </p>
                                    <p >
                                    As we are rebuilding our website, please continue to reach out to us at <a href="mailto:gis@toraki.com">gis@toraki.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            
        </>
    );
}

export default About;