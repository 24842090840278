import { useLayoutEffect } from "react";

function Slider() {

    useLayoutEffect(() => {
        // @ts-ignore
        $('#ensign-nivoslider').nivoSlider({
            effect: 'random',
            slices: 15,
            boxCols: 12,
            boxRows: 8,
            animSpeed: 500,
            pauseTime: 5000,
            startSlide: 0,
            directionNav: true,
            controlNavThumbs: false,
            pauseOnHover: true,
            manualAdvance: false,
          });
    }, [])
    
    return (
        <>
            <div id="home" className="slider-area">
                <div className="bend niceties preview-2">
                    <div id="ensign-nivoslider" className="slides">
                        <img src="/assets/img/slider/slider1.jpg" alt="" title="#slider-direction-1" />
                        <img src="/assets/img/slider/slider2.jpg" alt="" title="#slider-direction-2" />
                        {/* <img src="/assets/img/slider/slider3.jpg" alt="" title="#slider-direction-3" /> */}
                    </div>


                    <div id="slider-direction-1" className="slider-direction slider-one">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="slider-content">

                                        <div className="layer-1-1 hidden-xs wow slideInDown" data-wow-duration="2s" data-wow-delay=".2s">
                                            <h2 className="title1">Welcome to the GI REVOLUTION</h2>
                                        </div>

                                        <div className="layer-1-2 wow slideInUp" data-wow-duration="2s" data-wow-delay=".1s">
                                            <h1 className="title2">TORAKI is dedicated to supporting participation in worldwide sport.</h1>
                                        </div>

                                        {/* <div className="layer-1-3 hidden-xs wow slideInUp" data-wow-duration="2s" data-wow-delay=".2s">
                                            <a className="ready-btn right-btn page-scroll" href="#portfolio">See Products</a>
                                            <a className="ready-btn page-scroll" href="#about">Learn More</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id="slider-direction-2" className="slider-direction slider-two">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="slider-content text-center">

                                        <div className="layer-1-1 hidden-xs wow slideInUp" data-wow-duration="2s" data-wow-delay=".2s">
                                            <h2 className="title1">Welcome to the GI REVOLUTION</h2>
                                        </div>

                                        <div className="layer-1-2 wow slideInUp" data-wow-duration="2s" data-wow-delay=".1s">
                                            <h1 className="title2">We apologize while our website is under construction</h1>
                                        </div>

                                        {/* <div className="layer-1-3 hidden-xs wow slideInUp" data-wow-duration="2s" data-wow-delay=".2s">
                                            <a className="ready-btn right-btn page-scroll" href="#portfolio">See Products</a>
                                            <a className="ready-btn page-scroll" href="#about">Learn More</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div id="slider-direction-3" className="slider-direction slider-two">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="slider-content">

                                        <div className="layer-1-1 hidden-xs wow slideInUp" data-wow-duration="2s" data-wow-delay=".2s">
                                            <h2 className="title1">The Best business Information </h2>
                                        </div>

                                        <div className="layer-1-2 wow slideInUp" data-wow-duration="2s" data-wow-delay=".1s">
                                            <h1 className="title2">Helping Business Security  & Peace of Mind for Your Family</h1>
                                        </div>

                                        <div className="layer-1-3 hidden-xs wow slideInUp" data-wow-duration="2s" data-wow-delay=".2s">
                                            <a className="ready-btn right-btn page-scroll" href="#services">See Services</a>
                                            <a className="ready-btn page-scroll" href="#about">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>



            
        </>
    );
}

export default Slider;